.document {
  padding: 20rem 35rem 10rem;
  @media screen and (max-width: 1800px) {
    padding: 20rem 5rem 10rem;
  }
  @include breakpoint-down(medium) {
    padding: 18rem 4rem 8rem;
  }
  @include breakpoint-down(small) {
    padding: 15rem 2rem 5rem;
  }
  &__heading {
    text-align: center;
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 5rem;
    @include breakpoint-down(small) {
      font-size: 3rem;
    }
  }
  &__content {
    table {
      border: 2px solid #000;
      text-align: center;
      margin-block: 5rem;
      width: 100%;
      th {
        border-bottom: 2px solid #0065a3;
        font-size: 2rem;
        color: #fff;
        background-color: #0065a3;
        padding: 3rem 4rem;
        @include breakpoint-down(small) {
          font-size: 1.6rem;
        }
      }
      td {
        border: 2px solid #000;
        font-size: 1.6rem;
        @include breakpoint-down(small) {
          font-size: 1.4rem;
        }
      }
    }
    ul {
      margin-left: 5rem;
      li {
        margin-bottom: 1rem;
        font-size: 16px;
        line-height: 30px;
        @include breakpoint-down(small) {
          font-size: 15px;
        }
      }
    }
    p {
      margin-bottom: 2rem;
      font-size: 16px;
      line-height: 30px;
      @include breakpoint-down(small) {
        font-size: 15px;
      }
    }
    h2 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 2rem;
    }
    h4 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 2rem;
      line-height: 40px;
      @include breakpoint-down(small) {
        font-size: 20px;
      }
    }
  }
}
