.trading__platform-heading {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background-image: url("../assets/trading.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 50rem 35rem 10rem;
  @media screen and (max-width: 1800px) {
    padding: 50rem 5rem 10rem;
  }
  @include breakpoint-down(medium) {
    padding: 50rem 4rem 10rem;
  }
  @include breakpoint-down(small) {
    padding: 40rem 2rem 30rem;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      1.79deg,
      #02134f 40.31%,
      rgba(2, 19, 79, 0) 100.2%
    );
    opacity: 0.9;
  }
  h2 {
    z-index: 10;
    position: relative;
    text-align: center;
    color: #fff;
    font-size: 4.5rem;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-weight: 600;
    @include breakpoint-down(medium) {
      font-size: 3.8rem;
    }
    @include breakpoint-down(small) {
      font-size: 3rem;
    }
  }
}
.trading__platform {
  padding: 10rem 35rem;
  @media screen and (max-width: 1800px) {
    padding: 10rem 5rem;
  }
  @include breakpoint-down(medium) {
    padding: 10rem 4rem;
  }
  @include breakpoint-down(small) {
    padding: 10rem 2rem;
  }
  .web__trader {
    &-1 {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      @include breakpoint-down(medium) {
        flex-direction: column-reverse;
      }
      img {
        @include breakpoint-down(medium) {
          margin-block: 5rem;
        }
        @include breakpoint-down(small) {
          width: 100%;
        }
      }
      &-content {
        margin-left: 5rem;
        margin-bottom: 3rem;
        @include breakpoint-down(medium) {
          margin-left: 0rem;
          margin-bottom: 0rem;
        }
        h2 {
          font-size: 4rem;
          margin-bottom: 2rem;
          text-transform: uppercase;
          @include breakpoint-down(medium) {
            font-size: 3.5rem;
          }
          @include breakpoint-down(small) {
            font-size: 2.5rem;
          }
        }
        p {
          font-size: 16px;
          line-height: 30px;
          margin-bottom: 2rem;
        }
      }
    }
    &-2 {
      p {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 2rem;
      }
    }
    &-info {
      margin-top: 5rem;
      text-align: center;
      &-btn {
        padding: 2rem 6rem;
        font-size: 18px;
        font-weight: 500;
        border: none;
        margin: 5rem 0rem;
        background: #0065a3;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
        @include breakpoint-down(small) {
          padding: 1rem 4rem;
        }
      }
      &-heading {
        margin-bottom: 3rem;
        h2 {
          font-size: 4rem;
          margin-bottom: 2rem;
          text-transform: uppercase;
          @include breakpoint-down(medium) {
            font-size: 3.5rem;
          }
          @include breakpoint-down(small) {
            font-size: 2.5rem;
          }
        }
      }
      &-1 {
        flex-basis: 40%;
        margin-right: 10rem;
        @include breakpoint-down(medium) {
          margin-right: 0rem;
        }
      }
      &-2 {
        flex-basis: 40%;
      }
    }
    &-infos {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      @include breakpoint-down(medium) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 5rem;
      }
      @include breakpoint-down(small) {
        margin-left: 2rem;
      }
    }
  }
}
.wb-info {
  display: flex;
  align-items: center;
  margin-block: 2rem;
  @include breakpoint-down(small) {
    align-items: flex-start;
  }
  p {
    font-size: 16px;
    line-height: 30px;
    text-align: left;
  }
  i {
    font-size: 16px;
    color: #0065a3;
    margin-right: 1rem;
    @include breakpoint-down(small) {
      margin-top: 1rem;
    }
  }
}
