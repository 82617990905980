@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&family=Raleway:wght@300;400;500;600&display=swap");
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Raleway";
  font-weight: 400;
  color: #000;
}
a,
a:visited,
a:hover {
  text-decoration: none;
  cursor: pointer;
}
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: "Raleway";
}
p {
  font-family: "Open Sans";
  margin: 0;
}
br {
  @include breakpoint-down(medium) {
    display: none;
  }
}
