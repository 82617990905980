.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in 200ms ease-in-out forwards;
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
.fade-out {
  animation: fade-out 200ms ease-in-out forwards;
}
.headerW {
  background-color: #fff;
}
header {
  background: transparent;
  position: absolute;
  width: 100%;
  z-index: 1000;
  .open {
    border: none !important;

    > span:first-child {
      transform: rotate(45deg);
      background-color: #0065a3;
    }
    > span:nth-child(2) {
      opacity: 0;
    }
    > span:last-child {
      transform: rotate(-45deg);
      background-color: #0065a3;
    }
  }
}
.nav {
  position: relative;
  padding: 2rem 35rem;
  @media screen and (max-width: 1600px) {
    padding: 2rem 4rem;
  }
  @include breakpoint-down(small) {
    padding: 2rem;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__hamburger {
    cursor: pointer;
    z-index: 1000;
    border: 2px solid #fff;
    padding: 1rem;
    > span {
      display: block;
      width: 30px;
      height: 3px;
      border-radius: 60px;
      background-color: #fff;
      transition: all 300ms ease-in-out;
      transform-origin: 1px 2px;
      z-index: 90;
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }
  }
  &__logo {
    img {
      width: 300px;
      height: 78px;
      @include breakpoint-down(medium) {
        width: 250px;
        height: 65px;
      }
      @include breakpoint-down(small) {
        width: 200px;
        height: 52px;
      }
      @media screen and (max-width: 400px) {
        width: 125px;
        height: 32px;
      }
    }
  }
  .darkBtn {
    border: 2px solid #000 !important;
    color: #000 !important;
  }
  .dark {
    border: 2px solid #000;
    span {
      background-color: #000;
    }
  }
  &__flex {
    display: flex;
    flex-direction: row;
    .hide {
      display: none;
    }
    .login {
      padding: 1rem 2rem;
      margin-right: 2rem;
      border: 2px solid #fff;
      font-size: 2rem;
      color: #fff;
      text-align: center;
      font-weight: 500;
      text-transform: uppercase;
      @include breakpoint-down(small) {
        display: none;
      }
    }
  }
  &__links {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    background: #fff;
    height: 400px;
    @include breakpoint-down(medium) {
      top: 90px;
    }
  }
}
.navBtns {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 5rem;
  @media screen and (max-width: 900px) {
    align-items: center;
    margin-right: 0rem;
  }
  .loginBtn {
    width: 250px;
    height: 70px;
    margin-bottom: 8px;
    border: 3px solid #0065a3;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    padding: 0px !important;
    color: #0065a3;
    font-weight: 700;
    &:hover {
      background-color: #0065a3;
      color: #fff;
    }
    @media screen and (max-width: 900px) {
      background-color: #0065a3;
      padding: 9px 1px 1px 1px !important;
      color: #fff;
      margin-bottom: 2rem;
      height: 60px;
    }
    @media screen and (max-width: 300px) {
      width: 100%;
    }
  }
}
