.hero {
  width: 100%;
  height: 90vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background-image: url("../assets/homeHero.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 1600px) {
    height: 100%;
  }
  @media screen and (max-width: 350px) {
    height: 100%;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #02134f;
    opacity: 0.6;
  }
  &__wrapper {
    position: relative;
    text-align: left;
    z-index: 10;
    padding: 30rem 35rem 0rem;
    @media screen and (max-width: 1600px) {
      padding: 24rem 5rem 10rem;
    }
    @include breakpoint-down(medium) {
      padding: 24rem 4rem 10rem;
    }
    @include breakpoint-down(small) {
      padding: 24rem 2rem 10rem;
    }
  }
  &__heading {
    margin-bottom: 2rem;
    h2 {
      font-size: 5.5rem;
      font-family: "Raleway";
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
      line-height: 62px;

      @include breakpoint-down(medium) {
        font-size: 4.5rem;
      }
      @include breakpoint-down(small) {
        font-size: 3.8rem;
      }
    }
  }
  &__text {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: 300;
    color: #fff;
    line-height: 35px;
    letter-spacing: 0.02em;
    margin-bottom: 4rem;
    @include breakpoint-down(medium) {
      font-size: 1.8rem;
      line-height: 4.4rem;
    }
    @include breakpoint-down(small) {
      font-size: 1.6rem;
      line-height: 4.4rem;
    }
  }
  &__btn {
    position: relative;
    top: 20px;
    border: 3px solid #fff;
    color: #fff;
    font-size: 2.4rem;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    background: transparent;
    padding: 2rem 5rem;
    font-size: 2rem;
    text-decoration: none;
    @include breakpoint-down(small) {
      padding: 2rem 3.5rem;
      font-size: 1.8rem;
    }
    &:hover {
      background: #fff;
      color: #333;
    }
  }
  &__info {
    position: relative;
    top: 200px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1600px) {
      padding: 0rem 5rem;
      top: 100px;
    }
    @include breakpoint-down(medium) {
      padding: 0rem 4rem;
    }
    @include breakpoint-down(small) {
      padding: 0rem 0rem;
      top: 100px;
    }
    h4 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 198px;
      height: 194px;
      font-size: 3rem;
      font-weight: 600;
      color: #0065a3;
      margin-inline: 10rem;
      text-align: center;
      background: #fff;
      box-shadow: 1px 4px 4px rgba(0, 101, 163, 0.3);
      @include breakpoint-down(medium) {
        width: 100%;
        margin-inline: 2rem;
      }
      @include breakpoint-down(small) {
        margin-inline: 1rem;
        font-size: 1.8rem;
      }
    }
  }
}
.trade__info {
  &__wrapper {
    padding: 20rem 35rem 10rem;
    @media screen and (max-width: 1800px) {
      padding: 20rem 5rem 10rem;
    }
    @include breakpoint-down(medium) {
      padding: 20rem 4rem 10rem;
    }
    @include breakpoint-down(small) {
      padding: 20rem 2rem 10rem;
    }
  }
  &-details {
    p {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 2rem;
      @media screen and (max-width: 1800px) {
        br {
          display: none;
        }
      }
    }
  }
  &-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row-reverse;
    @include breakpoint-down(medium) {
      flex-direction: column;
      br {
        display: none;
      }
    }
    &-info {
      flex-basis: 45%;
      h2 {
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 52px;
        margin-bottom: 2rem;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #02134f;
        @include breakpoint-down(medium) {
          font-size: 3.8rem;
        }
        @include breakpoint-down(small) {
          font-size: 3.2rem;
        }
      }
      p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 2rem;
        @media screen and (max-width: 1800px) {
          br {
            display: none;
          }
        }
      }
    }
    &-img {
      flex-basis: 50%;
      margin-right: 5rem;
      @include breakpoint-down(medium) {
        margin-right: 0rem;
        margin-block: 5rem;
      }
      img {
        width: 100%;
      }
    }
  }
} // Trade
.trade {
  position: relative;
  background-image: url("../assets/stockmarket.png");
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
  @media screen and (max-width: 1464px) {
    background-size: cover;
  }
  @include breakpoint-down(medium) {
    background-position: center;
    background-size: cover;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        60.1deg,
        #02134f 40.14%,
        rgba(2, 19, 79, 0) 73.74%
      ),
      linear-gradient(356.39deg, #02134f 46.49%, rgba(2, 19, 79, 0) 55.52%);
  }

  &__wrapper {
    padding: 10rem 35rem;
    @media screen and (max-width: 1800px) {
      padding: 10rem 5rem;
    }
    @include breakpoint-down(medium) {
      padding: 10rem 4rem;
    }
    @include breakpoint-down(small) {
      padding: 10rem 2rem;
    }
  }
  &__heading {
    position: relative;
    @include breakpoint-down(medium) {
      padding: 2rem 0rem 2rem 6rem;
    }
    @include breakpoint-down(small) {
      padding: 2rem;
      padding-top: 0rem;
    }
    h2 {
      text-transform: uppercase;
      font-size: 4.4rem;
      font-family: "Raleway";
      font-weight: 600;
      color: #fff;
      z-index: 1;
      margin-block: 1rem;
      letter-spacing: 0.05em;
      @include breakpoint-down(medium) {
        text-align: center;
      }
      @include breakpoint-down(small) {
        font-size: 3.5rem;
      }
    }
    p {
      color: #fff;
      z-index: 1;
      margin-top: 5rem;
      font-size: 1.6rem;
      line-height: 30px;
      @include breakpoint-down(medium) {
        text-align: center;
      }
    }
  }
  &__content {
    position: relative;

    h2 {
      font-size: 2.5rem;
      font-family: "Raleway";
      font-weight: 600;
      z-index: 1;
      color: #fff;
      margin-block: 5rem;
      @include breakpoint-down(medium) {
        text-align: center;
      }
      @include breakpoint-down(small) {
        font-size: 2rem;
      }
    }
    .trade__content-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-basis: 100%;
      z-index: 1;
      margin-bottom: 1rem;
      .transparent {
        border: 2px solid #fff;
        color: #fff;
      }
      .trade___single-card {
        background: #fff;
        border-radius: 10px;
        margin-right: 0.5rem;
        margin-bottom: 1rem;
        padding: 1rem;
        height: 240px;
        width: 253px;
        flex-basis: 22%;
        text-align: center;
        @include breakpoint-down(medium) {
          flex-basis: 40%;
          margin: 1rem auto;
        }
        @include breakpoint-down(small) {
          flex-basis: 100%;
        }
        img {
          margin-block: 2rem;
        }
        h4 {
          font-size: 1.6rem;
          font-family: "Raleway";
          font-weight: 600;
          line-height: 25px;
          @include breakpoint-down(small) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
.slider {
  margin: 10rem auto 0rem;

  .rec.rec-arrow {
    display: none;
  }
  .rec.rec-pagination {
    position: relative;
    top: -120px;

    @media screen and (max-width: 600px) {
      top: 0px;
      margin-bottom: 1rem;
    }
  }
  .rec {
    z-index: 100;
  }
  .rec .rec-dot_active {
    @include breakpoint-up(medium) {
      background-color: #fff;
      box-shadow: 0 0 1px 3px rgba(229, 230, 231, 0.3);
    }
  }
  .rec .rec-dot {
    @include breakpoint-up(medium) {
      z-index: 100;
      box-shadow: 0 0 1px 2px rgba(255, 255, 255, 0.3);
    }
  }
  .rec .rec-slider-container {
    margin: 0;
  }
  .rec.rec-pagination:focus {
    @include breakpoint-up(medium) {
      outline: none;
      box-shadow: inset 0 0 1px 1px lightgrey;
    }
  }

  &__single {
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #003b7e;
      opacity: 0.5;
    }

    &-wrapper {
      position: relative;
      z-index: 10;
      padding: 15rem 35rem;
      @include breakpoint-down(medium) {
        padding: 15rem 4rem;
      }
      @include breakpoint-down(small) {
        padding: 10rem 2rem;
      }
    }
    &-content {
      br {
        @include breakpoint-down(medium) {
          display: none;
        }
      }
      &-heading {
        font-size: 3.8rem;
        text-transform: uppercase;
        line-height: 50px;
        letter-spacing: 0.05em;
        color: #fff;
        margin-bottom: 5rem;
        @include breakpoint-down(medium) {
          font-size: 3rem;
        }
        @include breakpoint-down(small) {
          font-size: 2.5rem;
        }
      }
      &-btns {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        &-1 {
          padding: 1rem 4rem;
          border: 3px solid #0065a3;
          background-color: #0065a3;
          font-size: 2rem;
          font-family: "Raleway";
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
          margin-right: 2rem;
          cursor: pointer;
          &:hover {
            background-color: transparent;
            color: #fff;
          }
        }
        &-2 {
          background-color: transparent;
          padding: 1rem 4rem;
          border: 3px solid #0065a3;
          font-size: 2rem;
          font-family: "Raleway";
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
          margin-right: 5rem;
          cursor: pointer;
          &:hover {
            color: #fff;
            background-color: #0065a3;
          }
        }
      }
      &-btn {
        background-color: #fff;
        padding: 1rem 4rem;
        border: 2px solid #fff;
        background-color: #fff;
        font-size: 2rem;
        font-family: "Raleway";
        font-weight: 600;
        color: #0065a3;
        text-transform: uppercase;
        cursor: pointer;
        a {
          color: #003b7e;
        }
        &:hover {
          background-color: transparent;
          color: #fff;
          a {
            color: #fff;
          }
        }
      }
    }
  }
}

// about
.about {
  position: relative;
  background-image: url("../assets/homeImage.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  padding: 2rem 0rem;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      85.67deg,
      #02134f 100%,
      rgba(2, 19, 79, 0.34375) 61.2%,
      rgba(2, 19, 79, 0) 187.6%
    );
    transform: matrix(-1, 0, 0, 1, 0, 0);
    opacity: 0.8;
  }
  &__wrapper {
    padding: 5rem 35rem 0rem;
    @media screen and (max-width: 1800px) {
      padding: 5rem 5rem 0rem;
    }
    @include breakpoint-down(medium) {
      padding: 5rem 4rem 0rem;
    }
    @include breakpoint-down(small) {
      padding: 5rem 2rem 0rem;
    }
  }
  @include breakpoint-down(medium) {
    br {
      display: none;
    }
  }
  &__heading {
    position: relative;
    @include breakpoint-down(medium) {
      br {
        display: none;
      }
    }
    h2 {
      font-family: "Raleway";
      font-weight: 600;
      font-size: 4rem;
      letter-spacing: 0.05em;
      color: #fff;
      z-index: 1;
      margin-bottom: 2rem;
      text-transform: uppercase;
      @include breakpoint-down(medium) {
        font-size: 3.5rem;
      }
      @include breakpoint-down(small) {
        font-size: 2.5rem;
      }
    }
    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 30px;
      color: #fff;
      margin-bottom: 5rem;
      z-index: 1;
      @include breakpoint-down(small) {
        font-size: 1.6rem;
      }
    }
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-basis: 100%;
    @include breakpoint-down(medium) {
      flex-direction: column;
    }

    .about__card {
      flex-basis: 44%;
      margin-bottom: 5rem;
      &:not(:nth-child(2)) {
        margin-right: 5rem;
      }
      &:not(:nth-child(4)) {
        margin-right: 5rem;
      }
      @include breakpoint-down(medium) {
        margin-bottom: 4rem;
        &:not(:nth-child(2)) {
          margin-right: 0rem;
        }
        &:not(:nth-child(4)) {
          margin-right: 0rem;
        }
      }
      @include breakpoint-down(small) {
        margin-bottom: 4rem;
      }
      h2 {
        font-size: 2.4rem;
        color: #fff;
        z-index: 1;
        padding-right: 2rem;
        margin-bottom: 2rem;
        line-height: 35px;
        @include breakpoint-down(small) {
          font-size: 2rem;
        }
      }
      p {
        color: #fff;
        font-size: 1.6rem;
        z-index: 1;
        line-height: 30px;
        @include breakpoint-down(small) {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.steps {
  &__wrapper {
    padding: 10rem 35rem 0rem;
    @media screen and (max-width: 1800px) {
      padding: 8rem 5rem 0rem;
    }
    @include breakpoint-down(medium) {
      padding: 7rem 4rem 0rem;
    }
    @include breakpoint-down(small) {
      padding: 6rem 2rem 0rem;
    }
  }
  &__heading {
    font-size: 4.2rem;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10rem;
    @include breakpoint-down(medium) {
      font-size: 3.8rem;
    }
    @include breakpoint-down(small) {
      font-size: 3rem;
    }
  }
  &__contents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @include breakpoint-down(medium) {
      flex-direction: column;
    }
  }
  &__item {
    flex-basis: 50%;
    display: flex;
    margin-bottom: 10rem;
    flex-direction: row;
    align-items: flex-start;
    &-number {
      font-size: 15rem;
      font-weight: 600;
      color: #0065a3;
      line-height: 80px;
      margin-right: 3rem;
    }
    &-heading {
      font-size: 3rem;
      color: #0065a3;
      line-height: 35px;
      margin-bottom: 2rem;
    }
    &-text {
      font-size: 16px;
      line-height: 30px;
    }
  }
}
