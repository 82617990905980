.aboutus {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background-image: url("../assets/about.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #02134f;
    background: linear-gradient(
      1.79deg,
      #02134f 40.31%,
      rgba(2, 19, 79, 0) 200.2%
    );
    opacity: 1;
  }
  &__wrapper {
    position: relative;
    z-index: 10;
    color: #fff;
    padding: 50rem 35rem 10rem;
    @media screen and (max-width: 1800px) {
      padding: 40rem 5rem 10rem;
    }
    @include breakpoint-down(medium) {
      padding: 40rem 4rem 10rem;
    }
    @include breakpoint-down(small) {
      padding: 40rem 2rem 10rem;
    }
  }
  &__content {
    @include breakpoint-down(medium) {
      text-align: center;
    }
    &-heading {
      font-size: 5rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      @include breakpoint-down(small) {
        font-size: 2.8rem;
      }
    }
    &-text {
      p {
        margin-block: 2rem;
        font-size: 16px;
        line-height: 30px;
        @include breakpoint-down(small) {
          font-size: 14px;
        }
      }
    }
  }
}
.contact {
  padding: 10rem 35rem;
  @media screen and (max-width: 1800px) {
    padding: 10rem 5rem;
  }
  @include breakpoint-down(medium) {
    padding: 10rem 4rem;
  }
  @include breakpoint-down(small) {
    padding: 10rem 2rem;
  }
  &__heading {
    @include breakpoint-down(small) {
      text-align: center;
    }
    h2 {
      font-size: 4rem;
      font-weight: 600;
      color: #02134f;
      text-transform: uppercase;
      @include breakpoint-down(medium) {
        text-align: center;
      }
      @include breakpoint-down(small) {
        font-size: 2.8rem;
      }
    }
  }
  &__content {
    display: flex;
    margin-top: 5rem;
    flex-basis: 100%;
    @include breakpoint-down(medium) {
      flex-direction: column-reverse;
    }
    &-details {
      flex-basis: 50%;
      margin-right: 5rem;
      @include breakpoint-down(medium) {
        flex-basis: 100%;
      }
      @include breakpoint-down(medium) {
        margin-top: 5rem;
        margin-right: 0rem;
      }
      .date,
      .email,
      .phone {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @media screen and (max-width: 450px) {
          flex-wrap: wrap;
        }
        &:not(:last-child) {
          margin-bottom: 4rem;
        }
        img {
          margin-right: 2rem;
        }
        h4 {
          font-size: 2rem;
        }
      }
    }
    &-form {
      flex-basis: 45%;
      position: relative;
      background-image: url("../assets/form.png");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 2rem 4rem;
      @include breakpoint-down(medium) {
        flex-basis: 100%;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #02134f;
        opacity: 0.6;
      }
      .form__btn {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        margin: 4rem 0rem;
        font-size: 18px;
        border-radius: 6px;
        padding: 1rem 5rem;
        color: #fff;
        font-weight: 500;
        border: 2px solid #fff;
        background: transparent;

        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
      }
      &-heading {
        font-size: 3.4rem;
        font-weight: 600;
        text-align: center;
        position: relative;
        color: #fff;
        z-index: 10;
      }
      .input__box {
        width: 100%;
        margin-bottom: 10px;
        position: relative;
        z-index: 10;
        margin-top: 3rem;

        i {
          position: absolute;
          padding: 10px;
          min-width: 50px;
          text-align: center;
          color: #fff;
          font-size: 20px;
        }
        input {
          width: 100%;
          padding: 12px;
          text-align: center;
          background: transparent;
          border: none;
          color: #fff;
          border-bottom: 2px solid #fff;
          &::placeholder {
            font-size: 2rem;
            color: #fff;
          }
        }
      }
    }
  }
}
