.footer {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background-image: url("../assets/footer.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2c061f;
    opacity: 0.7;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 10;
    color: #fff;
    padding: 10rem 30rem 15rem;
    @media screen and (max-width: 1800px) {
      padding: 8rem 5rem 15rem;
    }
    @include breakpoint-down(medium) {
      padding: 7rem 4rem 15rem;
      flex-direction: column;
    }
    @include breakpoint-down(small) {
      padding: 6rem 2rem 15rem;
    }
  }
  &__btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 2rem;
    @include breakpoint-down(medium) {
      align-items: center;
    }
  }
  &__lg-btn {
    border: 2px solid #fff;
    padding: 1rem 0rem;
    width: 275px;
    margin-bottom: 2rem;
    font-size: 2.2rem;
    background: transparent;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
  }
  &__oa-btn {
    text-transform: uppercase;
    border: 2px solid #fff;
    padding: 1rem;
    width: 275px;
    font-size: 2.2rem;
    background: transparent;
    color: #fff;
    font-weight: 500;
    text-align: center;
  }
  &__content {
    flex-basis: 60%;
    margin-left: 4rem;
    @include breakpoint-down(medium) {
      text-align: center;
      margin-left: 0rem;
      margin-top: 3rem;
    }
    .fc__heading {
      font-size: 2rem;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;
    }

    .fc1 {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }
    p {
      font-size: 14px;
      line-height: 30px;
    }
  }
  &__links {
    flex-basis: 35%;
  }
  &__nav {
    margin-top: 10rem;
    @include breakpoint-down(medium) {
      margin-top: 5rem;
    }
    &__heading {
      font-size: 2.5rem;
      font-weight: 600;
      color: #0065a3;
      text-transform: uppercase;
      margin-bottom: 1rem;
      @include breakpoint-down(medium) {
        text-align: center;
      }
    }
    &__links {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      @include breakpoint-down(medium) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-block: 2rem;
      }
      .link1 {
        display: flex;
        flex-direction: column;
        margin-right: 10rem;
        @include breakpoint-down(medium) {
          margin-right: 0;
        }
        a {
          color: #fff;
          font-weight: 600;
          font-size: 1.4rem;
          text-transform: uppercase;
          line-height: 4rem;
        }
      }
      .link2 {
        display: flex;
        flex-direction: column;
        a {
          color: #fff;
          font-weight: 600;
          font-size: 1.4rem;
          text-transform: uppercase;
          line-height: 4rem;
        }
      }
    }
  }
}
.copyright {
  position: absolute;
  width: 100%;
  background: #fff;
  left: 0;
  right: 0;
  bottom: 20px;
  padding: 1rem 0rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  z-index: 10;
  @include breakpoint-down(small) {
    font-size: 12px;
  }
}
