.accounts {
  width: 100%;

  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background-color: #02134f;

  &__wrapper {
    position: relative;
    z-index: 10;
    padding: 25rem 35rem 40rem;
    @media screen and (max-width: 1800px) {
      padding: 25rem 5rem 40rem;
    }
    @include breakpoint-down(medium) {
      padding: 25rem 4rem 30rem;
    }
    @include breakpoint-down(small) {
      padding: 25rem 2rem 30rem;
    }
  }
  &__heading {
    text-align: center;
    @include breakpoint-down(medium) {
      padding: 0rem 3rem;
    }
    h2 {
      font-size: 4.8rem;
      font-weight: 600;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #fff;
      margin-bottom: 3rem;
      @include breakpoint-down(medium) {
        font-size: 3.4rem;
      }
      @include breakpoint-down(small) {
        font-size: 2.8rem;
      }
    }

    p {
      font-size: 1.6rem;
      color: #fff;
      line-height: 30px;
    }
  }
}
.pricing {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  top: -20rem;
  text-align: center;
  @include breakpoint-down(medium) {
    flex-direction: column;
    align-items: center;
    top: -20rem;
  }
  @include breakpoint-down(small) {
    flex-direction: column;
    align-items: center;
    top: -15rem;
  }
  .pricingM {
    margin-block: 0rem;
  }
  &__card {
    filter: drop-shadow(0px 15px 50px rgba(50, 50, 93, 0.25));
    &:not(:last-child) {
      margin-right: 5rem;
      @include breakpoint-down(medium) {
        margin-right: 0rem;
        margin-bottom: 5rem;
      }
    }
    p {
      font-size: 14px;
      color: #fff;
      margin-block: 1rem;
    }
    &-btn {
      background: #fff;
      color: #0065a3;
      border: none;
      padding: 2rem 6rem;
      margin: 4rem 0rem;
      font-size: 18px;
      @include breakpoint-down(small) {
        padding: 2rem 3rem;
        font-size: 16px;
      }
    }
    &-heading {
      padding: 4rem;
      h2 {
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: 500;
        color: #fff;
        @include breakpoint-down(small) {
          font-size: 3rem;
        }
      }
      p {
        font-size: 17px;
        color: #fff;
        @include breakpoint-down(small) {
          font-size: 15px;
        }
      }
      h3 {
        font-size: 3.4rem;
        font-weight: 500;
        color: #fff;
        @include breakpoint-down(small) {
          font-size: 3rem;
        }
      }
    }
    &-info {
      padding: 0rem 2rem;
      .info__box {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        i {
          margin-right: 1rem;
          color: #fff;
        }
      }
    }
  }
}
.account__funding {
  background: #f0f0f0;
  position: relative;
  top: -20rem;
  @include breakpoint-down(small) {
    top: -5rem;
  }
  &-wrapper {
    padding: 5rem 35rem;
    @media screen and (max-width: 1800px) {
      padding: 5rem 5rem;
    }
    @include breakpoint-down(medium) {
      padding: 5rem 4rem;
    }
    @include breakpoint-down(small) {
      padding: 5rem 2rem;
    }
  }
  &-btn {
    border: none;
    margin-top: 5rem;
    margin-bottom: 5rem;
    font-size: 18px;
    font-weight: 500;
    padding: 1rem 4rem;
    color: #fff;
    background: #0065a3;
  }
  &-heading {
    text-align: center;
    padding-top: 6rem;
    margin-bottom: 5rem;
    h2 {
      font-size: 4rem;
      font-weight: 600;
      color: #02134f;
      text-transform: uppercase;
      @include breakpoint-down(small) {
        font-size: 3rem;
      }
    }

    p {
      margin: 3rem;
      font-size: 18px;
      color: #3c3939;
      @include breakpoint-down(small) {
        font-size: 16px;
      }
    }
  }

  &-content {
    display: flex;

    @include breakpoint-down(medium) {
      padding: 0rem 4rem;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-content: center;
    }
    &-1 {
      flex-basis: 50%;
      margin-right: 15rem;
      @include breakpoint-down(medium) {
        margin-right: 0rem;
        margin-bottom: 5rem;
      }
      h4 {
        font-size: 2rem;
        font-weight: 600;
        margin-top: 6rem;
        line-height: 2;
      }
      .accCard1,
      .accCard2,
      .accCard3 {
        margin-bottom: 3rem;
        h2 {
          color: #02134f;
          font-size: 2.5rem;
          font-weight: 600;
          margin-bottom: 2rem;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          color: #3c3939;
          line-height: 30px;
        }
      }
    }
    &-2 {
      flex-basis: 50%;
      .acc__withdraw,
      .acc__things {
        h2 {
          font-size: 2.5rem;
          font-weight: 500;
          margin-bottom: 2rem;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 1rem;
        }
        .acc-info {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 3rem;
          p {
            margin-block: 1rem;
          }
          i {
            font-size: 15px;
            color: #0065a3;
            margin-top: 1.2rem;
            margin-right: 0.8rem;
          }
        }
      }
    }
  }
}
